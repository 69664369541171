@import url("https://pagecdn.io/lib/normalize/8.0.1/normalize.min.css");
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css");

// https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
@mixin visually-hidden() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

$base_space: 1em;
$icon_size: 3em;

html {
  font-family: sans-serif;
}

a.devicon_link {
  text-decoration: none;
  font-size: $icon_size;

  span {
    @include visually-hidden();
  }
}

header,
main {
  width: 90%;
  max-width: 992px;
}

header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin: $base_space auto $base_space auto;

  .title_subtitle {
    margin-bottom: $base_space;
  }

  .title {
    font-size: 1.5em;
    text-align: center;
    margin: 0 0 $base_space 0;
  }

  .subtitle {
    margin: 0;
  }

  img.avatar {
    border-radius: 50%;
    max-width: 150px;
    margin: 0;
  }
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto 0 auto;

  section {
    flex: 100%;
    margin: 0 0 $base_space 0;

    h2 {
      margin: 0 0 $base_space 0;
    }
  }
}

footer {
  margin: 0 $base_space $base_space $base_space;
  text-align: center;
}
